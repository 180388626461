import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Title from "../Global/Title"
import TestimonialCard from "./TestimonialCard"

export default function Testimonial() {
  // fetch from source
  const data = useStaticQuery(graphql`
    query {
      allTestimonial: allContentfulTestimonial(
        sort: { fields: order, order: ASC }
      ) {
        edges {
          node {
            id
            order
            client
            childContentfulTestimonialQuoteTextNode {
              quote
            }
            logo {
              fluid(
                maxWidth: 200
                maxHeight: 200
                resizingBehavior: NO_CHANGE
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }

      ustLogo: contentfulClient(name: { eq: "HKUST" }) {
        logo {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `)
  const allTestimonial = data.allTestimonial.edges
  const ustLogo = data.ustLogo.logo.fluid

  const [activeSlide, setSlide] = React.useState(1)

  const handleSlideChange = (event, index) => {
    if (index !== activeSlide) {
      setSlide(index)
    }
  }

  return (
    <section id="testimonial">
      <Title title="Testimonials" />
      <div className="separator" />

      <div id="testimonial-slider">
        {allTestimonial.map(({ node }, i) => (
          <TestimonialCard
            key={node.id}
            item={node}
            extraImg={node.client === "Praise-HK" ? ustLogo : null}
            index={i}
            activeSlide={activeSlide}
            handleSlideChange={handleSlideChange}
          />
        ))}
      </div>
    </section>
  )
}
