import React from "react"
import Title from "../Global/Title"
import SVGIcon from "../Global/SVGIcon";
import Img from "gatsby-image";

export default function ContactInfo({img}) {
  return (
    <section>
      <div>
        <div className="row" style={{ margin: 0 }}>
          <div className="col-10 col-md-6 text-center mx-auto my-5">
            <Title title="Kickstart your idea" />
            <div className="separator" />

            <p className="mx-auto custom-text">
              GIANTHOUSE is the place where creativity meets execution. It is
              composed of creators and developers embracing technology. We
              love to discuss ideas and are excited to hear from you. Let us
              build something great together.
            </p>
            <a href="https://wa.me/85298305435" target="_blank" rel="noopener noreferrer">
              <button className="btn default-btn text-uppercase btn-round">
                <SVGIcon
                  name="whatsapp"
                  width={20}
                  className="contact-icon"
                />
                Message Us
              </button>
            </a>
          </div>

          <div className="side-phone">
            <Img fluid={img} className="phone" alt="Macbook photo" />
          </div>
        </div>
      </div>
    </section>
  )
}
