import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BannerSection from "../components/Global/TopBanner"
import HomeInfo from "../components/Home/HomeInfo"
import WhatWeDoInfo from "../components/Home/WhatWeDoInfo"
import ContactInfo from "../components/Home/ContactInfo"
import Testimonial from "../components/Home/Testimonial"
import Clients from "../components/Home/Clients"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="GIANTHOUSE"/>
    <BannerSection
      img={data.img.childImageSharp.fluid}
      title="We build ideas."
      styleClass="default-banner"
    />
    <HomeInfo img={data.devopsImg.childImageSharp.fluid} />
    <WhatWeDoInfo />
    <Testimonial />
    <Clients />
    <ContactInfo img={data.phoneImg.childImageSharp.fluid} />
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: { eq: "home-banner.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 1920
          quality: 100
          duotone: { highlight: "#071730", shadow: "#071730", opacity: 1 }
        ) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    devopsImg: file(relativePath: { eq: "devops.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    phoneImg: file(relativePath: { eq: "home-side.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default IndexPage
