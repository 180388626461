import React from "react"
import Title from "../Global/Title"
import SVGIcon from "../Global/SVGIcon"
import { Link } from "gatsby"

export default function WhatWeDoInfo() {
  return (
    <section className="white-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-8 mx-auto text-center">
            <div className="row-container">
              <Title title="We Do With" />
              <div className="heart" />
            </div>
            <div className="separator" />

            <div className="row justify-content-center icon-grid">
              <div className="col-sm-10 col-lg-4 wrap-item">
                <SVGIcon name="mobile" width={60} className="info-icon" />
                <h6>Mobile App</h6>
                <p>
                  We design and develop hybrid and native apps for iOS and
                  Android.
                </p>
              </div>
              <div className="col-sm-10 col-lg-4 wrap-item">
                <SVGIcon name="webapp" width={60} className="info-icon" />
                <h6>Web App</h6>
                <p>
                  Responsible web apps are developed using the latest web
                  technologies.
                </p>
              </div>
              <div className="col-sm-10 col-lg-4 wrap-item">
                <SVGIcon name="design" width={60} className="info-icon" />
                <h6>UI/UX Design</h6>
                <p>
                  Amazing designs and smooth user journey are the keys to
                  successful apps.
                </p>
              </div>
              <div className="col-sm-10 col-lg-4 wrap-item">
                <SVGIcon name="ecommerce" width={60} className="info-icon" />
                <h6>Ecommerce</h6>
                <p>
                  We offer high quality, secure & custom ecommerce development
                  services and solutions for businesses.{" "}
                </p>
              </div>
              <div className="col-sm-10 col-lg-4 wrap-item">
                <SVGIcon name="blockchain" width={60} className="info-icon" />
                <h6>Blockchain</h6>
                <p>
                  Our blockchain solutions can help you embrace the power of
                  distributed ledger technology.
                </p>
              </div>

              <div className="col-sm-10 col-lg-4 wrap-item">
                <SVGIcon name="gear" width={60} className="info-icon" />
                <h6>Custom Module</h6>
                <p>
                  We provide customised IT solutions according to business
                  needs.
                </p>
              </div>
            </div>
            <Link to="/showcase">
              <button className="btn default-btn text-uppercase btn-round">
                See our work
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
