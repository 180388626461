import React from "react"
import Title from "../Global/Title"
import Img from "gatsby-image"

export default function HomeInfo({ img }) {
  return (
    <section id="about-gh">
      <div className="container">
        <div className="row">
          <div className="col-10 col-sm-8 mx-auto text-center">
            <Title title="About GIANTHOUSE" />
            <div className="separator" />
            <p className="mb-5">
              GIANTHOUSE was started by the spirit of turning imaginations into
              reality. It is a software house specialized in developing website
              and mobile applications, as well as providing customized IT and
              digital solutions. Our vision is to offer affordable service in
              order to promote a wider adoption of technology in the society.
              Backed by venture capital, we are formed by a group of experienced
              developers and designers based in Hong Kong. Our clients include
              large corporate as well as early-stage startups. With deep
              understanding in technology and business, we develop or even
              invest in interesting projects. Agile with DevOps practice is the
              culture we adopted to deliver market-adaptive and premium digital
              experiences.
            </p>
            <Img fluid={img} className="dev" alt="devOps graph" />
          </div>
        </div>
      </div>
    </section>
  )
}
