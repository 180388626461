import React from "react"
import Img from "gatsby-image"
import { FaQuoteLeft } from "react-icons/fa"

const TestimonialCard = (props) => {
  const { item, index, activeSlide, handleSlideChange, extraImg } = props
  // set style
  let classNames = "testimonial-card"
  if (activeSlide === index) classNames += " active-card"
  else if (activeSlide - 1 === index) classNames += " prev-card"
  else if (activeSlide + 1 === index) classNames += " next-card"

  return (
    <div
      className={classNames}
      onClick={(e) => handleSlideChange(e.nativeEvent, index)}
      role="presentation"
    >
      {item.logo ? (
        <div className="flex-row">
          <div className="testimonial-logo-wrapper">
            <Img
              fluid={item.logo.fluid}
              imgStyle={{ objectFit: "contain" }}
              className={`testimonial-logo ${
                index === activeSlide && "active-logo"
              }`}
              alt={`${item.client} logo`}
            />
          </div>
          {item.client === "Praise-HK" && (
            <div className="testimonial-logo-wrapper">
              <Img
                fluid={extraImg}
                imgStyle={{ objectFit: "contain" }}
                className={`testimonial-logo ${
                  index === activeSlide && "active-logo"
                }`}
                alt={`hkust logo`}
              />
            </div>
          )}
        </div>
      ) : (
        <p className="testimonial-client">{item.client}</p>
      )}

      <div className="content">
        <FaQuoteLeft color={"var(--mainTeal)"} size={25} />
        <p className="testimonial-content">
          {item.childContentfulTestimonialQuoteTextNode.quote}
        </p>
      </div>
    </div>
  )
}

export default TestimonialCard
