import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Title from "../Global/Title"

export default function Clients() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulClient {
        nodes {
          id
          name
          logo {
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)
  const clients = data.allContentfulClient.nodes

  return (
    <section className="white-container">
      <div className="container">
        <div className="row">
          <div className="col-10 col-sm-8 mx-auto text-center">
            <Title title="Brands we work with" />
            <div className="separator" />
            <div className="client-logo-container">
              {clients.map((c) => (
                <Img key={c.id} fluid={c.logo.fluid} alt={c.name} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
